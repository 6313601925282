import '@/global/dayjs'
import '@/global/number'
import useAppPage from '@/hooks/use-app-page'
import useUpgrade from '@/hooks/useUpgrade'
import '@/styles/globals.scss'
import ErrorBoundary from '@/ui/components/shared/error-boundary'
import { RootConfig, RootLayout } from '@/ui/components/shared/layout'
import Loading from '@/ui/widgets/loading'
import { resource } from '@/utils/helper'
import { Modal, Spin } from 'antd'
import Head from 'next/head'
import { Router, useRouter } from 'next/router'
import NProgress from 'nprogress'
import React, { ReactElement, useCallback, useEffect } from 'react'

Spin.setDefaultIndicator(<Loading />)

interface PageProps {
  Component: typeof React.Component & { getLayout?: (props: any) => ReactElement }
  pageProps: any
}

const Heads = () => (
  <Head>
    <title>蔡司工业质量商城</title>
    <meta content="width=device-width, initial-scale=1, maximum-scale=1" name="viewport" />
    <link rel="icon" href={resource('/favicon.png')} />
  </Head>
)

export default function Page({ Component, pageProps }: PageProps) {
  const router = useRouter()
  const { events, pathname: currentPathname } = router

  const appPage = useAppPage(
    <ErrorBoundary>
      <Component {...pageProps} />
    </ErrorBoundary>
  )

  const renderWithLayout = Component.getLayout
    ? (props: any) => <RootConfig>{Component.getLayout!(props)}</RootConfig>
    : function (page: ReactElement) {
        return (
          <RootConfig>
            <RootLayout>{page}</RootLayout>
          </RootConfig>
        )
      }

  const onNProgressStart = useCallback(
    (nextPath: string) => {
      const nextPathname = nextPath.split('?')[0]
      if (!nextPathname.endsWith(currentPathname)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        NProgress.start()
      }
    },
    [currentPathname]
  )
  const onNProgressDone = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    NProgress.done()
  }, [])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    NProgress.configure({ showSpinner: false })
    events.on('routeChangeStart', onNProgressStart)
    events.on('routeChangeComplete', onNProgressDone)
    events.on('routeChangeError', onNProgressDone)
    return () => {
      events.off('routeChangeStart', onNProgressStart)
      events.off('routeChangeComplete', onNProgressDone)
      events.off('routeChangeError', onNProgressDone)
    }
  }, [events, onNProgressDone, onNProgressStart])

  useEffect(() => {
    return () => {
      Modal.destroyAll()
    }
  }, [currentPathname])

  const { pass, LoadingDom } = useUpgrade()
  if (!pass) {
    return <LoadingDom />
  }

  return (
    <>
      <Heads />
      {renderWithLayout(appPage)}
    </>
  )
}
